<template>

  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="mt-2 mb-2 mr-2">

        <!-- Table Top -->
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>Secondary Beneficial Owners</h3>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  :to="{ name: 'iab-client-secondary-owner-create', query: { 'clientId': clientId } }"
                  variant="primary"
              >
                <span class="text-nowrap">Add Secondary Owner</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <div class="m-2 pb-4 text-center" v-if="totalOwners === 0">
        <h3 class="mt-3">This client don't have any secondary owners yet. Would you like to add one?</h3>
        <b-button :to="{ name: 'iab-client-secondary-owner-create', query: { 'clientId': clientId } }">Create Secondary Owner</b-button>
      </div>

      <b-table
          v-if="totalOwners > 0"
          :items="owners"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'iab-client-secondary-owner', params: { id: data.item.id } }">{{ data.item.name }}</b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'iab-client-secondary-owner-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteOwner(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import clientSecondaryOwnerService from "@core/services/client-secondary-owner/useClientSecondaryOwner";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {$themeConfig} from "@themeConfig";

export default {
  props: ['clientId'],
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.getOwners();
  },
  data() {
    return {
      userData: {},
      owners: [],
      totalOwners: 0,
      tableColumns: [
        {key: 'name', sortable: true},
        {key: 'title', sortable: true},
        {key: 'email', sortable: true},
        {key: 'actions'},
      ],
      perPage: 20,
      currentPage: 1,
      searchQuery: ""
    }
  },
  methods: {
    getOwners() {
      clientSecondaryOwnerService.getByClient({
        'client_id': this.clientId
      }).then(response => {
        this.owners = response.data.owners;
        this.totalOwners = response.data.totalOwners;
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    },
    goToOwnerCreate() {
      window.location.href = '/client-secondary-owner/create';
    },
    deleteOwner(clientOwner){
      clientSecondaryOwnerService.deleteClientOwner({
        'id': clientOwner.id
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success!`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `You have successfully deleted this owner`,
          },
        })

        this.getOwners();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
