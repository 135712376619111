import BaseService from "@core/services/baseService";

export default class ClientSecondaryOwnerService extends BaseService {
    createClientSecondaryOwner(...args){
        return this.axiosIns.post(this.formConfig.clientSecondaryOwnerEndpoint, ...args)
    }

    updateClientSecondaryOwner(...args){
        return this.axiosIns.post(this.formConfig.clientSecondaryOwnerEndpoint+"/edit/"+args[0].id, ...args)
    }

    getClientSecondaryOwner(...args){
        return this.axiosIns.get(this.formConfig.clientSecondaryOwnerEndpoint+"/"+args[0].id, ...args)
    }

    deleteClientSecondaryOwner(...args){
        return this.axiosIns.delete(this.formConfig.clientSecondaryOwnerEndpoint+"/"+args[0].id)
    }

    getByClient(...args){
        return this.axiosIns.get(this.formConfig.clientSecondaryOwnerEndpoint, {params: {'client_id': args[0].client_id}})
    }
}